
import React from 'react';
import './style.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {isEmpty} from 'loadsh'
import { MyContext } from "../../containers/context/index";




class SubHeader extends React.Component {


  getEta=()=>{
    let eta;
    let a = (this.props.eta).substring(0,2);
    let b = (this.props.eta).substring(3);
    if(a>12){
      eta = (a-12)+":"+b+" PM";
    }else{
      eta = a+":"+b+" PM";
    }
    return eta;
  }
  getAddress=()=>{
    let details = this.props.trackingDetails.processUpdateForm ?JSON.parse(this.props.trackingDetails.processUpdateForm):'';
    let address= '';
    if(!isEmpty(details.addressLine1)){
       address=details.addressLine1;
    }
    if(!isEmpty(details.addressLine2)){
      address = !isEmpty(address)?address+", "+details.addressLine2:details.addressLine2;
    }
    if(!isEmpty(details.landMark)){
     address = !isEmpty(address)?address+", "+details.landMark:details.landMark;
 
    }
     if(!isEmpty(details.pinCode)){
       address = !isEmpty(address)?address+", "+details.pinCode:details.pinCode;      
     }
     if(!isEmpty(details.contact)){
      address = !isEmpty(address)?address+", "+details.contact:details.contact;      
    }
        
    return address;
 }
  showViewDetailButton = () =>{
    if(this.props.trackingDetails.layoutScreen.childMapping && this.props.trackingDetails.layoutScreen.childMapping.length>0){
      for(let i=0;i<this.props.trackingDetails.layoutScreen.childMapping.length;i++){
        if(!isEmpty(this.props.trackingDetails.layoutScreen.childMapping[i].value))
        return true;
      }
    }
    
    if(this.props.trackingDetails.layoutScreen.attributeList && this.props.trackingDetails.layoutScreen.attributeList.length>0){
      for(let i=0;i<this.props.trackingDetails.layoutScreen.attributeList.length;i++){
        if(!isEmpty(this.props.trackingDetails.layoutScreen.attributeList[i].value))
        return true;
      }
    }
    if(this.props.trackingDetails.layoutScreen.orderAttributeList && this.props.trackingDetails.layoutScreen.orderAttributeList.length>0){
      for(let i=0;i<this.props.trackingDetails.layoutScreen.orderAttributeList.length;i++){
        if(!isEmpty(this.props.trackingDetails.layoutScreen.orderAttributeList[i].value))
        return true;
      }
    }
    if(this.getAddress() && this.getAddress()!='')
    return true;
    if(this.props.trackingDetails.processStatusLogDomainList && this.props.trackingDetails.processStatusLogDomainList.length>0)
    return true;

    return false;
            
  }
  render() {
    const ctaFontColor = this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaFontColor;
    const ctaColorWhite = this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaColorWhite;
    const fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO.newCiData.defaultSettings.typeface.fontType:"Roboto";
    let  {referenceNumber,packageDetails,awb ,trackingAttribute}=this.props.trackingDetails;
    return (
    <MyContext.Consumer>
    {({
      secondaryBgColor,
      secondaryTextColor,
      fontSize,
      fontFamily
        }) => (
         <div className="subheader" 
              style={{backgroundColor:secondaryBgColor? secondaryBgColor: null,
                color: secondaryTextColor, 
                fontSize:fontSize,
                fontFamily:fontFamily }}
        >
      <Grid container spacing={0}>
          <Grid item xs={7}>
          <div className="tracking-no-div" style={{fontFamily:`${fontType||"Roboto"},sans-serif`}}>
              <div className="tracking-no-string">
                <p>{awb?awb:this.props.trackingDetails.feedback_config?this.props.trackingDetails.feedback_config:containerConstants.formatString(containerConstants.TrackingNo)} </p>
              </div>
              <div className="tracking-number">
                <p>{trackingAttribute ? trackingAttribute : referenceNumber} </p>
              </div>
              <div className="details">
              { this.props.details?
                      <Button style={{backgroundColor:secondaryBgColor,color: secondaryTextColor? secondaryTextColor: '#18BF9A'}}
                              onClick={this.props.openEventScreen}>
                        {packageDetails?packageDetails:containerConstants.formatString(containerConstants.Details)}
                      </Button>:null
              }
              </div>
          </div>
          </Grid>
        {this.props.landingLayout && this.showViewDetailButton() ?
        <Grid item xs={5}  className="viewdetails" >
        <Button id="viewDetailsButton" 
        style={{
          backgroundColor: (ctaColorWhite?"#ffffff":secondaryBgColor?secondaryBgColor:"#ffffff"),
          color: (ctaFontColor?ctaFontColor:(this.props.primaryTextColor?this.props.primaryTextColor:this.props.primaryBgColor?this.props.primaryBgColor:"#49bd94")),
        }}
        // style={{color: this.props.trackingDetails
        //          .customerInteractionThemeSettingsDTO &&
        //        this.props.trackingDetails.customerInteractionThemeSettingsDTO
        //          .primaryBgColor? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        //          .primaryBgColor: '#000000'}}
        onClick ={this.props.showOrderTracking}
        >{containerConstants.formatString(containerConstants.ViewDetails)}</Button>
        </Grid>
        :this.props.customScreen?
          <Grid item xs={5}  className="eta-screen">
          <div className="view-detail" >
              <p onClick={()=>this.props.showCustomScreen()} className="pointer" style={{color:this.props.textColor}}>{containerConstants.formatString(containerConstants.VIEW_DETAILS)}
              {window.sessionStorage.getItem('languageDirection')=="rtl"?<NavigateBeforeIcon className="text-gray"/>
                                :<NavigateNextIcon className="text-gray"/>}
              </p>
              </div>
          
          </Grid> :<Grid item xs={5}  className="eta-screen">
          <div className="eta-div" >
          { (this.props.trackingDetails.showEta && this.props.tripEta && this.props.tripEta.actualEta && !((this.props.tripEta.actualEta).indexOf("undefine")!=-1 || (this.props.tripEta.actualEta).indexOf("Na")!=-1
            || (this.props.tripEta.time).indexOf("undefine")!=-1 || (this.props.tripEta.time).indexOf("Na")!=-1 ))? 
            <div className="eta-arriving-status" style={{color:secondaryTextColor}}>
                <p >{"Arriving "+this.props.tripEta.actualEta}</p>
                <p>{this.props.tripEta.time?this.props.tripEta.time:''}</p>
            </div>:
            <div className="eta-arriving-status" style={{color:secondaryTextColor}}>
                <p >{this.props.eta?"Arriving in ":''}</p>
                <p >{this.props.eta?this.props.eta:''}</p>
            </div>
           }
          </div>
        </Grid> 
      }
      </Grid> 
      </div>
    )}
    </MyContext.Consumer>
    );
  }
}

export default SubHeader;
