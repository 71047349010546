import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import Calendar from 'react-calendar';
import DateTimePicker from 'react-datetime-picker';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { isEmpty, cloneDeep} from 'lodash'
import { convertFromStandardFormat } from '../../Utils/dateTimeUtil';

class FormAttributes extends React.Component {
    deliveryDate;
    minDate;
    maxDate;
    aftreDays;
    beforeDays;
    disabledDates=[];
    dayMap = 
    { 0:containerConstants.formatString(containerConstants.Sunday),
     1: containerConstants.formatString(containerConstants.Monday), 
     2: containerConstants.formatString(containerConstants.Tuesday), 
     3: containerConstants.formatString(containerConstants.Wednesday),
     4: containerConstants.formatString(containerConstants.Thursday), 
     5: containerConstants.formatString(containerConstants.Friday), 
     6: containerConstants.formatString(containerConstants.Saturday) };
    monthMap = 
    {0:containerConstants.formatString(containerConstants.January),
     1: containerConstants.formatString(containerConstants.February), 
     2: containerConstants.formatString(containerConstants.March), 
     3: containerConstants.formatString(containerConstants.April), 
     4: containerConstants.formatString(containerConstants.May), 
     5: containerConstants.formatString(containerConstants.June), 
     6: containerConstants.formatString(containerConstants.July), 
     7: containerConstants.formatString(containerConstants.August), 
     8: containerConstants.formatString(containerConstants.September), 
     9: containerConstants.formatString(containerConstants.October), 
     10:containerConstants.formatString(containerConstants.November), 
     11: containerConstants.formatString(containerConstants.December) }
    selectedSlotDate;
    selectedSlot;
    invalidChars = ["-", "e"];



    constructor(props) {
        super(props);
        this.state = {
            calendar: false,
            dateTimeCalendar: false,
            leftIndex: -1,
            rightIndex: -1,
            selectedSlotDate: null,
            selectedSlot: {},
            locationValue: '',
            initialLocationValueEmpty: false,
            isSlotDrawerOpen: false,
            holiday:{count:0,dates:{}}
        }
        this.calendarType = 'US';
        this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
        this.fontType = this.props.trackingDetails?.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO.newCiData.defaultSettings.typeface.fontType:"Roboto";
    }

    getSimpleTypeAttributes = (attribute) => {
        let { typeId, key, label, required, notEditable } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <TextField
                id="standard-name"
                variant="outlined"
                label={label}
                margin="normal"
                className="width-100"
                value={typeId == 52 ? (!isEmpty(this.props.latLngValue) ? this.props.latLngValue : this.state.locationValue)
                    : this.props.formDetailsMap[key].value}
                type={(typeId == 6 || typeId == 31) ? "Number" : ""}
                onClick={!notEditable && this.props.checkLatLng(attribute, this.props.index)}
                required={required || !this.props.isAddressSubmitEnabled}
                onChange={event => {
                    if(typeId==27){
                        if(/^[+]?[0-9]*$/.test(event.target.value)){
                            this.props.updateValue(key, event.target.value)
                        } 
                    } else {
                        this.props.updateValue(key, event.target.value)
                    }
                    
                }}
                onKeyPress={(event) => {
                    if (this.invalidChars.includes(event.key) && (typeId == 6 || typeId == 27 || typeId == 31)) {
                        event.preventDefault();
                    }
                }}
                onBlur={() => { this.props.runValidation(key, "route") }}
                autoComplete='off'
                InputLabelProps={{
                    style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                }}
                InputProps={{
                    style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                }}
                disabled={notEditable}

            />
        );
    }


    calenderDrawer = (calendar, min, max, value,beforeDays, afterDays) => () => {
        if (min && !isEmpty(min)) {
            this.minDate = min;
        }
        if (max && !isEmpty(max)) {
            this.maxDate = max;
        }
        if (value) {
            this.deliveryDate = value;
        }
        if (beforeDays && !isEmpty(beforeDays)) {
            this.beforeDays = beforeDays;
        }
        if (afterDays && !isEmpty(afterDays)) {
            this.afterDays = afterDays;
        }

        this.setState({
            calendar: calendar
        });
    };

    getDateTypeAttribute = (attribute) => {
        let { key, label, value, required, minRange, maxRange, notEditable, beforeDays, afterDays } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        // remove this after prod release...
        if (!isEmpty(value)) {
            let date = new Date(value);
            let currentDate = new Date();
            if (currentDate.getTime() >= date.getTime()) {
                currentDate.setDate(currentDate.getDate() + 1);
                date = currentDate;
                let year = date.getFullYear();
                let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
                let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
                value = year + '-' + month + '-' + day;
                if (!isEmpty(minRange)) {
                    minRange = value;
                }
            }
        }
        return (
            <div>
                <TextField
                    id="standard-name"
                    variant="outlined"
                    label={label}
                    margin="normal"
                    className="width-100"
                    required={required}
                    onClick={!notEditable && this.calenderDrawer(true, minRange, maxRange, value, beforeDays, afterDays)}
                    value={value ? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) : ''}
                    autoComplete='off'
                    InputLabelProps={{
                        style: { color: this.props.fontColor,fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}

                />
            </div>
        )
    }


    updateCheckedValues = (key, checked, code) => {
        let checkedArray = [];
        if (checked) {
            if (isEmpty(this.props.formDetailsMap[key].value)) {
                checkedArray.push(code)
                this.props.updateValue(key, checkedArray)
            } else {
                checkedArray = cloneDeep(this.props.formDetailsMap[key].value);
                checkedArray.push(code);
                this.props.updateValue(key, checkedArray)
            }
        } else if (!isEmpty(this.props.formDetailsMap[key].value)) {
            checkedArray = this.props.formDetailsMap[key].value.filter(val => val !== code);
            this.props.updateValue(key, checkedArray);
        }
    }

    getCheckedValues = (key, code) => {
        const checkedArray = this.props.formDetailsMap[key].value;
        if (!isEmpty(checkedArray)) {
            for (let i = 0; i < checkedArray.length; i++) {
                if (checkedArray[i] == code)
                    return true;
            }
        }
        return false;
    }



    getCheckBoxMenuTypeAttribute = (attribute) => {
        let { key, required, processAttributesValues, notEditable } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div >
                <FormGroup onBlur={() => { this.props.runValidation(key, "route") }}>
                    {processAttributesValues.map((box) => {
                        return (
                            <FormControlLabel
                                key={box.code}
                                control={
                                    <Checkbox
                                        checked={this.getCheckedValues(key, box.code)}
                                        value={box.code}
                                        onChange={event => {
                                            this.updateCheckedValues(key, event.target.checked, box.code)
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        style={{ color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize }}>
                                        {required ? box.name + "*" : box.name}
                                    </Typography>}
                                disabled={notEditable}
                            />
                        )
                    })
                    }

                </FormGroup>
            </div>
        )
    }


    getDropDownSelectTypeAttribute = (attribute) => {
        let { key, label, required, processAttributesValues, notEditable } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div>
                <TextField
                    select
                    margin="normal"
                    variant="outlined"
                    className="width-100"
                    label={label}
                    value={this.props.formDetailsMap[key].value}
                    autoComplete='off'
                    required={required}
                    onChange={event => {
                        this.props.updateValue(key, event.target.value)
                    }}
                    onBlur={() => { this.props.runValidation(key, "route") }}
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}
                >
                    {processAttributesValues.map(option => (
                        <MenuItem key={option.name} value={option.code} style={{ color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        )
    }

    moveToLeft = () => event => {
        if (this.state.leftIndex - 3 > 1) {
            this.leftIndex = this.leftIndex - 3;
            this.rightIndex = this.leftIndex + 2;
            this.setState({ 'leftIndex': this.state.leftIndex - 3, 'rightIndex': this.state.leftIndex -1 })
        }
        else if (this.state.leftIndex > 0) {
            this.leftIndex = 0;
            this.rightIndex = this.leftIndex + 2;
            this.setState({ 'leftIndex': 0, 'rightIndex': 2 })

        }
        else {
            //disabled
        }
    }

    moveToRight = (dynamicSlotDate) => event => {
        if (this.state.rightIndex + 3 < dynamicSlotDate.length) {
            this.leftIndex = this.rightIndex + 1;
            this.rightIndex = this.rightIndex + 3;
            this.setState({ 'leftIndex': this.state.rightIndex + 1, 'rightIndex': this.state.rightIndex + 3 })
        } else if (this.state.rightIndex < dynamicSlotDate.length) {
            this.rightIndex = dynamicSlotDate.length - 1;
            this.leftIndex = this.rightIndex - 2;
            this.setState({ 'leftIndex': dynamicSlotDate.length - 3, 'rightIndex': dynamicSlotDate.length - 1 })

        } else {
            //disabled
        }
    }

    getAvailableSlots = (date,coordinatorSlotObject) => {
        this.setState({ selectedSlotDate: date, selectedSlot: {} });
        this.props.getDynamicSlots(date,coordinatorSlotObject);
    }

    selectSlotValue = slot => {
        this.props.setDynamicSlots(slot);
        this.setState({ 'selectedSlot': slot.slotNumber });
    }

    getDynamicDate = (value, dynamicSlotDate, minRange, maxRange) => {

        let date = [];
        if (!this.state.selectedSlotDate) {
            this.setState({ 'selectedSlotDate': value });
        }
        for (let i = this.state.leftIndex; i <= this.state.rightIndex; i++) {
            if (dynamicSlotDate && dynamicSlotDate[i]) {
                let date_array = dynamicSlotDate[i].split("-");
                let day=date_array[2];
                let dateObject = new Date(date_array[0],date_array[1]-1,date_array[2]);
                let dayName = dateObject.getDay()
                let monthName = dateObject.getMonth();
                let year = dateObject.getFullYear().toString().substr(2);
                
                if (day < 10) {
                    day = day.replace(/^0+/, '');    // if day is less then 10 then  show only single digit
                }
                date.push(
                    <div className={`date-block ${this.state.selectedSlotDate == dynamicSlotDate[i] ? "active" : ''}`} onClick={() => this.getAvailableSlots(dynamicSlotDate[i],this.props.availableDatesFromCoordinator[dynamicSlotDate[i]])}>
                        <p className="day-name">{this.dayMap[dayName].substr(0,3).toUpperCase()}</p>
                        <p style={{ textAlign: 'center' }}>{day}</p>
                        <p className="month-year">{`${this.monthMap[monthName].substr(0,3).toUpperCase()}\`${year}`}</p>
                    </div>
                )
            }
        }
        return date;

    }


    getDynamicSlotTypeAttribute = (attribute,dynamicSlotDates) => {
        let { key, label, value, required, minRange, maxRange, hidden} = attribute;
        let alertColor = this.props.alertMessage == "No Available Slot Found" ? 'red' : 'green';

        if (this.props.formDetailsMap[key].hide || isEmpty(dynamicSlotDates)) {
            return null;
        }

        dynamicSlotDates.sort(function(a, b) {
            return new Date(a) -  new Date(b);
        });

        if (this.state.leftIndex == -1) {
            if (minRange != 0) {
                let min = JSON.parse(minRange);
                this.setState({ 'leftIndex': min - 1, 'rightIndex': min + 1 })
            } else {
                this.setState({ 'leftIndex': 0, 'rightIndex': 2 })
            }
            return;
        }

        return (
            <div>
                <div className="date-block">
                    <div className="slot-date-blocks">
                        {this.getDynamicDate(value, dynamicSlotDates, minRange, maxRange)}
                        <div className="slot-date-nav">
                            <div className="slot-nav-left" onClick={this.moveToLeft()}>
                                <img src={require('../../images/left-arrow.png')} />
                            </div>
                            <div className="slot-nav-right" onClick={this.moveToRight(dynamicSlotDates)}>
                                <img src={require('../../images/right-arrow.png')} />
                            </div>
                        </div>
                    </div>
                </div>
                {(!(!isEmpty(this.props.config3PLDataMap) && this.props.config3PLDataMap[this.props.form.title] && this.props.config3PLDataMap[this.props.form.title].fullDaySlotsEnabled) && !isEmpty(this.props.slotList)) ?
                        <div className="slot-time ml15">
                            <p style={{ marginTop: '5px', marginBottom: '5px' }}>{label}</p>
                            <div className="slot-blocks">
                                {(this.props.slotList).map((slot, index) => {
                                    return <div className={`${this.state.selectedSlot == slot.slotNumber ? "active" : ''}`} key={index} 
                                        onClick={()=>this.selectSlotValue(slot)}
                                    >{slot.start || slot.startTime}-{slot.end || slot.endTime}</div>
                                })}
                            </div>

                        </div> : null
                }
                <p style={{ color: alertColor, marginLeft: '15px', marginTop: '2px' }} className="fs15">{this.props.alertMessage}</p>

            </div>
        )
    }

    getDateTimeTypeAttribute = (attribute) => {
        let { key, label, value, required, hidden, notEditable } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div>
                <TextField
                    id="standard-name"
                    variant="outlined"
                    label={label}
                    margin="normal"
                    className="width-100"
                    required={required}
                    onClick={event => { !notEditable && this.setState({ 'dateTimeCalendar': !this.state.dateTimeCalendar }) }}
                    value={value ? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) : ''}
                    autoComplete='off'
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}
                />
            </div>
        )
    }

    getTileClassName(date, view) {
        let classes = [];
        if (date && date.date.getDay() == 6) {
            classes.push('tileClassName');

        }
        return classes;

    }

    getActiveDate = () => {
        if (this.deliveryDate && !isEmpty(this.deliveryDate)) {
            return new Date(this.deliveryDate);
        } else {
            return new Date();
        }
    }


    handleLanguage = languageCode => {
        if (languageCode && languageCode == "ar") {
            return languageCode;
        }
        else {
            return languageCode = 'en';
        }

    }

    formatDate = (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1
        const year = date.getFullYear();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        return [year, month, day].join('-');
    }

    checkAnnualHolidays = (date) => {
        const dateToCompare = this.formatDate(date);
        let bool = false;
        if (this.props.holidayMaster) {
            const holidayList = JSON.parse(this.props.holidayMaster.holidayList);
            const dates = holidayList.map(holiday => holiday.date);
            bool = dates.includes(dateToCompare);
        }
        return bool;
    }
    checkIfDateIncludedInHolidayMaster=(date)=>{
        if (isEmpty(this.props.holidayMaster)) {
            if (this.calendarType === 'Arabic') return date.getDay() == 5;
            if(this.calendarType=='US-SUN') return date.getDay() == 0;
        } else {
            let weekOffFlag = false;
            let annualOffFlag = false;
            let weekOffArray = [];
            let holiday;
            if (this.props.holidayMaster.weekOff.constructor === String) {
                weekOffArray = JSON.parse(this.props.holidayMaster.weekOff);
            } else if (this.props.holidayMaster.weekOff.constructor === Array) {
                weekOffArray = this.props.holidayMaster.weekOff;
            }
            weekOffFlag = weekOffArray.includes(date.getDay() + '') || (date.getDay()==0?weekOffArray.includes(7+''):weekOffArray.includes(date.getDay() + ""));
            annualOffFlag = this.checkAnnualHolidays(date);
            if((weekOffFlag || annualOffFlag) && 
                 (isEmpty(this.state.holiday["dates"])|| !this.state.holiday["dates"][date])){
                    holiday = this.state.holiday;
                    holiday["dates"][date] = date;
                    holiday["count"] = holiday["count"]+1;
                    this.setState({'holiday':this.state.holiday})
                
            }
            return weekOffFlag || annualOffFlag;
        }
    }

    checkIfDateDisable = (date) => {
        if(new Date(new Date().setHours(0,0,0,0)).getTime() == date.getTime()){   // disable current date from selection
            return true ;
        }
        else if (this.disabledDates.includes(date.getTime()))                     
        return true;
        if (isEmpty(this.props.holidayMaster)) {
            if (this.calendarType === 'Arabic') return date.getDay() == 5;
            if(this.calendarType=='US-SUN') return date.getDay() == 0;
        } else {
            let weekOffFlag = false;
            let annualOffFlag = false;
            let weekOffArray = [];
            let holiday;
            if (this.props.holidayMaster.weekOff.constructor === String) {
                weekOffArray = JSON.parse(this.props.holidayMaster.weekOff);
            } else if (this.props.holidayMaster.weekOff.constructor === Array) {
                weekOffArray = this.props.holidayMaster.weekOff;
            }
            weekOffFlag = weekOffArray.includes(date.getDay() + '') || (date.getDay()==0?weekOffArray.includes(7+''):weekOffArray.includes(date.getDay() + ""));
            annualOffFlag = this.checkAnnualHolidays(date);
            if((weekOffFlag || annualOffFlag) && 
                 (isEmpty(this.state.holiday["dates"])|| !this.state.holiday["dates"][date])){
                    holiday = this.state.holiday;
                    holiday["dates"][date] = date;
                    holiday["count"] = holiday["count"]+1;
                    this.setState({'holiday':this.state.holiday})
                
            }
            return weekOffFlag || annualOffFlag;
        }
    }

    isSlotDateDisabled = (date,dynamicSlotDates) => {
       const fullMonth =  (date.getMonth()) < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);               
       const fullDate = (date.getDate()+'').length == 2 ? date.getDate() : "0"+date.getDate();         
       const formattedDate = date.getFullYear()+"-"+fullMonth+"-"+fullDate;
       return !dynamicSlotDates.includes(formattedDate);
    }

    updateDateType =(key,value)=>{
        this.props.updateAttributeValue(this.props.index,key,value);
        this.props.updateValue(key, value);
    }
    setDisabledDate =()=>{
     if(this.props.attribute?.beforeDays || this.props.attribute?.afterDays){
        let beforeDays = this.props.attribute?.beforeDays || 0;
        let afterDays = this.props.attribute?.afterDays || 0;
       this.disabledDates=[]
       let currentDate = new Date();
       let defaultDate = new Date(new Date().setHours(0,0,0,0));
       if(this.props?.attribute?.value){
        currentDate=new Date(new Date(this.props.attribute.value).setHours(0,0,0,0));
        defaultDate=new Date(new Date(this.props.attribute.value).setHours(0,0,0,0));
       }
       let currentDate3 = cloneDeep(currentDate);
       let currentDate4 = cloneDeep(currentDate);
       let currentDate2= cloneDeep(currentDate);
        let startDate= new Date(currentDate.setDate(currentDate.getDate() - parseInt(beforeDays)));
        let endDate = new Date(currentDate2.setDate(currentDate2.getDate() + parseInt(afterDays)));
      while(startDate <= currentDate4){
        if(defaultDate.getTime() != currentDate4.getTime()){
          this.disabledDates.push( new Date(new Date(currentDate4).setHours(0,0,0,0)).getTime());
          if(this.checkIfDateIncludedInHolidayMaster(currentDate4)){
            startDate.setDate(startDate.getDate() -1);
          }
        } 
          
          currentDate4.setDate(currentDate4.getDate() -1);
      }
      while(currentDate3 <= endDate){
        if(defaultDate.getTime() != currentDate3.getTime()){
          this.disabledDates.push( new Date(new Date(currentDate3).setHours(0,0,0,0)).getTime());
          if(this.checkIfDateIncludedInHolidayMaster(currentDate3)){
            endDate.setDate(endDate.getDate() +1);
        }
        } 
          
          currentDate3.setDate(currentDate3.getDate() +1);
      }
        // while(startDate <= endDate){
        //   if(defaultDate.getTime() != startDate.getTime()){
        //     this.disabledDates.push( new Date(new Date(startDate).setHours(0,0,0,0)).getTime());
        //   } 
        //     if(this.checkIfDateIncludedInHolidayMaster(startDate)){
        //         endDate.setDate(endDate.getDate() +1);
        //     }
        //     startDate.setDate(startDate.getDate() +1);
        // }
    }
    }
    checkToDisableDate =(date)=>{
        if(new Date(new Date().setHours(0,0,0,0)).getTime() == date.getTime()){   // disable current date from selection
            return true ;
        }
        else if (this.disabledDates.includes(date.getTime()))                     
        return true;
    return false; 
    }
    getCalendarDrawer = () => {
        const calendars = ['US', 'Arabic', 'Hebrew', 'ISO 8601','US-SUN'];
        if (calendars.indexOf(this.props.calendarType) > -1) {
            this.calendarType = cloneDeep(this.props.calendarType);
        }
        return (
            <Drawer
                anchor="bottom"
                open={this.state.calendar}
                onClose={this.calenderDrawer(false)}>
                <Calendar
                    locale={this.handleLanguage(this.props.language)}
                    tileClassName={this.getTileClassName}
                    tileDisabled={({ date }) => this.checkIfDateDisable(date)}
                    calendarType={this.calendarType.indexOf('US-SUN')!=-1?'US':this.calendarType}
                    onChange={(event) => {
                        let year = event.getFullYear();
                        let month = (event.getMonth() + 1) < 10 ? '0' + (event.getMonth() + 1) : (event.getMonth() + 1);
                        let date = event.getDate() < 10 ? '0' + event.getDate() : event.getDate()
                        let value = year + '-' + month + '-' + date;
                        this.updateDateType(this.props.attribute.key,value)
                    }}
                    value={this.props.attribute.value}//test
                    onClickDay={this.calenderDrawer(false)}
                    maxDate={this.maxDate && !isEmpty(this.maxDate) ? this.getMaxDateForCalendar() : ''}
                    minDate={this.minDate && !isEmpty(this.minDate) ? new Date(this.minDate) : ''}
                    value={!isEmpty(this.deliveryDate) ? new Date(this.deliveryDate) : new Date()}
                />
            </Drawer>
        )
    }

    getMaxDateForCalendar=()=>{
          if(this.maxDate && !isEmpty(this.maxDate)){
              if(this.state.holiday["count"]>0){
                  var date = new Date(this.maxDate);
                  date.setDate(date.getDate()+(this.state.holiday["count"]));
                  return date;
          }  else {
              return new Date(this.maxDate);
          }
        }
    }

    getDateTimeDrawer = () => {
        return (
            <Drawer
                anchor="bottom"
                open={this.state.calenderTimeDrawer}
                onClose={event => {
                    this.setState({ 'dateTimeCalendar': !this.state.dateTimeCalendar })
                }}>
                <DateTimePicker
                    onChange={(event) => {
                    }}
                    value={this.props.attribute.value}
                    onClickDay={''}
                />
            </Drawer>
        )
    }

    getDynamicSlotDatesDrawer = (value,dynamicSlotDates,minRange,maxRange) => {
        return (
            <Drawer
                anchor="bottom"
                open={this.state.isSlotDrawerOpen}
                onClose={() => {
                    this.setState({ 'isSlotDrawerOpen': false })
                }}>
                 <Calendar
                    locale={this.handleLanguage(this.props.language)}
                    tileClassName={this.getTileClassName}
                    tileDisabled={({ date }) => this.isSlotDateDisabled(date,this.dynamicSlotDates)}
                    calendarType={this.calendarType}
                    value={!isEmpty(this.state.selectedSlotDate) ? new Date(this.state.selectedSlotDate) : !this.isSlotDateDisabled(new Date(this.props.attribute.value),this.dynamicSlotDates || [])? new Date(this.props.attribute.value) : null}          
                    onClickDay={()=>this.setState({isSlotDrawerOpen: false})}
                    onChange={(event) => {
                        let year = event.getFullYear();
                        let month = (event.getMonth() + 1) < 10 ? '0' + (event.getMonth() + 1) : (event.getMonth() + 1);
                        let date = event.getDate() < 10 ? '0' + event.getDate() : event.getDate()
                        let formattedDate = year + '-' + month + '-' + date;
                        this.getAvailableSlots(formattedDate,this.props.availableDatesFromCoordinator[formattedDate]);
                    }}
                  
                />
            </Drawer>
        )
    }
    
    getDynamicSlotTypeAttributeDrawerView = (attribute,dynamicSlotDates) => {
        let { key, label, value, required, minRange, maxRange, hidden } = attribute;
        if(this.props.attribute.value && !dynamicSlotDates.includes(this.props.attribute.value)){
            dynamicSlotDates.push(this.props.attribute.value);
        }
        return (
            <div>
                 <div>
                    <TextField
                        id="standard-name"
                        label={label}
                        margin="normal"
                        className="width-100"
                        required={required}
                        onPaste={e=>e.preventDefault()}
                        onClick={()=>{
                            this.dynamicSlotDates = dynamicSlotDates;
                            this.setState({ 'isSlotDrawerOpen': true })}
                        }
                        onChange={(event) =>
                            this.setState({'selectedSlotDate':event.target.value})
                        }
                        value={isEmpty(this.state.selectedSlotDate)? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) :this.state.selectedSlotDate}
                        autoComplete='off'
                        InputLabelProps={{
                            style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                            className: (value && !isEmpty(value)) ? 'mui-label' : ''
                        }}
                        InputProps={{
                            style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                        }}
                    />
                </div>
                <div>
                  {this.getSlotsDropdown(attribute)}
                </div>
                </div>

        )

    }

    getSlotsDropdown = (attribute) => {
        let { key, label, required, processAttributesValues, notEditable } = attribute;
        if ((!isEmpty(this.props.config3PLDataMap) && this.props.config3PLDataMap[this.props.form.title] && this.props.config3PLDataMap[this.props.form.title].fullDaySlotsEnabled) ||
              this.props.formDetailsMap[key].hide || 
                 !this.state.selectedSlotDate) {
            return null;
        }
        return (
            <div>
                <TextField
                    select
                    margin="normal"
                    className="width-100"
                    autoComplete='off'
                    required={required}
                    label="Select Slot"
                    onChange={(event) => this.selectSlotValue(event.target.value)}
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}
                >
                    {(this.props.slotList || []).map(slot => (
                        <MenuItem key={slot} value={slot} style={{ color: this.props.fontColor, fontFamily: this.props.fontFamily ? this.props.fontFamily : this.props.fontType, fontSize: this.props.fontSize }}
                        >
                            {slot.start || slot.startTime}-{slot.end || slot.endTime}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        )
    }


    async checkLocationType() {
        // this.props.disabled is used to check if any required address type attribute is empty or not, 
        //  if any address required attribute is empty then it will not show latlng value by default, 
        // so user can understand that something is missing in address part
        if (this.props.isAddressSubmitEnabled && !this.state.initialLocationValueEmpty && (!this.state.locationValue || isEmpty(this.state.locationValue)) && this.props.attribute && this.props.attribute.typeId && this.props.attribute.typeId == 52 &&
            this.props.attribute.required && this.props.attribute.value && !isEmpty(this.props.attribute.value)) {
            let latlngs = (this.props.attribute.value).split(',');
            var geocoder = new google.maps.Geocoder;
            var latlng = { lat: parseFloat(latlngs[0]), lng: parseFloat(latlngs[1]) };
            if(this.herePoweredAccount){
                let address = latlng.lat+","+latlng.lng;
                let results = ""
                console.log("Response from", results);
                if(results.items[0]){
                    this.setState({ 'locationValue': results.items[0].address.label })
                }
                else{
                    this.setState({ 'initialLocationValueEmpty': true }); 
                }
            }
            else{
                geocoder.geocode({ 'location': latlng }, function (results, status) {
                    console.log("Response from", status, results);
                    if (status === 'OK' && results[0]) {
                        this.setState({ 'locationValue': results[0].formatted_address })
                    } else {
                        this.setState({ 'initialLocationValueEmpty': true });
                    }
                }.bind(this));
                }
         
        }
    }


    getAttributes = () => {
        let { typeId } = this.props.attribute;
        if (typeId) {
            switch (JSON.stringify(typeId)) {
                case '1':
                case '2':
                case '6':
                case '28':
                case '29':
                case '30':
                case '27':
                case '31':
                case '52':
                    return (<div>{this.getSimpleTypeAttributes(this.props.attribute)}</div>);
                case '8':
                    return (<div>{this.getCheckBoxMenuTypeAttribute(this.props.attribute)}</div>)
                case '10':
                    return (<div>{this.getDropDownSelectTypeAttribute(this.props.attribute)}</div>)
                case '3':
                    return (<div>{this.getDateTypeAttribute(this.props.attribute)}</div>);//todo
                case '4':
                    return (<div>{this.getDateTimeTypeAttribute(this.props.attribute)}</div>)//todo
                case '95':
                    return (this.props.form && this.props.form.dynamicSlotView?
                    <div>{this.getDynamicSlotTypeAttributeDrawerView(this.props.attribute,Object.keys(this.props.availableDatesFromCoordinator))}</div>:
                    <div>{this.getDynamicSlotTypeAttribute(this.props.attribute,Object.keys(this.props.availableDatesFromCoordinator))}</div>)//todo
                default:
                    return null;

            }
        }
    }

    render() {
        if(isEmpty(this.disabledDates)){
            this.setDisabledDate();
        }
        this.checkLocationType();
        return (
            <div>
                {this.getAttributes()}
                {this.getCalendarDrawer()}
                {this.getDateTimeDrawer()}
                {this.getDynamicSlotDatesDrawer()}
            </div>

        )
    }

}

export default FormAttributes;