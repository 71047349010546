import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LocateAddress from "../LocateAddress";
import ViewListIcon from "@material-ui/icons/ViewList";
import MyLocation from "@material-ui/icons/MyLocation";
import PudoScreenList from "../../components/PudoScreenList";
import CarouselComponent from "../../components/Carousel";
import ShopDetail from "../ShopDetail";
import { isEmpty } from "loadsh";
import MapView from "../MapView";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { setPudoPoints } from "../MapView/MapViewAction";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import EventScreen from "../EventScreen/index";
import SearchIcon from "@material-ui/icons/Search";

class AvailableLocation extends React.Component {
  storeSingleDetailIndex;
  map;
  bounds;
  jobLatitude;
  jobLongitude;
  searchLocationLat;
  searchLocationLng;
  isBackButtonClicked = false;
  constructor(props) {
    super(props);
    this.state = {
      pudoList: false,
      shopDetail: false,
      currentMarkerIndex: "",
      showLocateAddress: false,
      addressValue: "",
      sortedOnJob: true,
      pudoUpdated: false,
      pudoError: false,
    };
    this.openPudoScreenList = this.openPudoScreenList.bind(this);
    this.clickBackAvailableLocationScreen = this.clickBackAvailableLocationScreen.bind(
      this
    );
    this.openCardDetail = this.openCardDetail.bind(this);
    this.closeCardDetail = this.closeCardDetail.bind(this);
    this.points = this.points.bind(this);
    this.mapFitBound = this.mapFitBound.bind(this);
    this.setCurrentLocation = this.setCurrentLocation.bind(this);
    this.updatePudoPointAccordingToCurrentLocation = this.updatePudoPointAccordingToCurrentLocation.bind(
      this
    );

    this.unitMultiplier = null;
    this.updatePudo = this.updatePudo.bind(this);
    this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false;
    this.fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO.newCiData.defaultSettings.typeface.fontyType:"Roboto";    
  }

  openPudoScreenList() {
    this.setState({
      pudoList: true,
    });
  }
  clickBackAvailableLocationScreen() {
    this.setState({
      pudoList: false,
    });
    this.isBackButtonClicked = false;
  }
  openCardDetail(singleDetail, index) {
    this.storeSingleDetail = singleDetail;
    this.storeSingleDetailIndex = index;
    if (!isEmpty(singleDetail)) {
      this.setState({
        shopDetail: true,
      });
    }
  }

  closeCardDetail(singleDetail) {
    this.setState({
      shopDetail: false,
      pudoError: false,
    });
    this.isBackButtonClicked = false;
  }

  points(index) {
    this.setState({
      ...index,
      currentMarkerIndex: index,
    });
  }

  renderCardLayout = () => {
    return (
      <CarouselComponent
        availableList={this.props.pudoPoints}
        onForwardArrowClick={this.openCardDetail}
        currentIndex={this.state.currentMarkerIndex}
        primaryBgColor={this.props.primaryBgColor}
        unit={
          this.props.pudoConfig &&
          this.props.pudoConfig.distanceUnit == "kilometers"
            ? "Km"
            : this.props.pudoConfig.distanceUnit
        }
        primaryTextColor={this.props.primaryTextColor}
        timingLabel={this.props.pudoConfig.timingLabel}
      />
    );
  };

  componentDidMount() {
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + this.props.url
    ); // on back button from browser/mobile it will set current url
    window.addEventListener("popstate", this.onBackButtonEvent);
  }
  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onBackButtonEvent);
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      this.isBackButtonClicked = true;
      setTimeout(
        function () {
          if (this.state.showLocateAddress) {
            this.setState({ showLocateAddress: false });
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
            this.isBackButtonClicked = false;
          } else if (this.state.shopDetail) {
            this.closeCardDetail();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else if (this.state.pudoList) {
            this.clickBackAvailableLocationScreen();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else {
            this.backToScreen();
          }
        }.bind(this),
        100
      );
    }
  };

  backToScreen() {
    if (!this.state.sortedOnJob) {
      if (
        this.props.trackingDetails &&
        this.props.trackingDetails.jobLat &&
        this.props.trackingDetails.jobLng
      ) {
        this.sortPudoPoints(
          this.props.trackingDetails.jobLat,
          this.props.trackingDetails.jobLng
        );
      }
    }
    this.props.onBackClick();
    this.isBackButtonClicked = false;
  }

  componentDidUpdate() {
    if (
      !this.unitMultiplier &&
      this.props.trackingDetails &&
      this.props.trackingDetails.pudo &&
      this.props.trackingDetails.pudo.distanceUnit
    ) {
      // conversion from kilometers to conversion unit
      switch (this.props.trackingDetails.pudo.distanceUnit) {
        case "miles":
          this.unitMultiplier = 0.6214;
          break;

        case "kilometers":
          this.unitMultiplier = 1;
          break;

        case "yards":
          this.unitMultiplier = 1093.61;
          break;

        default:
          this.unitMultiplier = 1;
      }
    }
  }

  getDistanceBetweenLatLng = (lat1, lng1, lat2, lng2) => {
    const p = 0.017453292519943295;
    const c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;
    let distanceInKilometer = 12742 * Math.asin(Math.sqrt(a));
    let convertedDistance = this.unitMultiplier
      ? distanceInKilometer * this.unitMultiplier
      : distanceInKilometer;
    return convertedDistance.toFixed(2);
  };

  sortPudoPoints = (lat, lng) => {
    if (!isEmpty(this.props.pudoPoints)) {
      const changedPudoPoints = [];
      this.props.pudoPoints.forEach((pudoPoint) => {
        let temp = { ...pudoPoint };
        temp.distance = this.getDistanceBetweenLatLng(
          lat,
          lng,
          pudoPoint.latitude,
          pudoPoint.longitude
        );
        changedPudoPoints.push(temp);
      });
      changedPudoPoints.sort((a, b) => (a.distance < b.distance ? -1 : 1));
      console.log(changedPudoPoints);
      this.props.actions.setPudoPoints(changedPudoPoints);
    }
  };

  setSearchAddress = (address) => {
    if (!isEmpty(address)) {
      this.searchLocationLat = address.geometry ?address.geometry.location.lat : address.latitude;
      this.searchLocationLng = address.geometry ?address.geometry.location.lng : address.longitude;
      this.sortPudoPoints(
        this.searchLocationLat ,
        this.searchLocationLng 
      );
      this.setState({
        showLocateAddress: false,
        sortedOnJob: false,
        addressValue: address.formatted_address,
      });
    }
  };

  onInputClear = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.jobLat &&
      this.props.trackingDetails.jobLng
    ) {
      this.sortPudoPoints(
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng
      );
    }
    this.setState({
      addressValue: "",
      sortedOnJob: true,
    });
  };

  setCurrentLocation = () => {
    this.mapFitBound(
      this.bounds,
      this.jobLatitude,
      this.jobLongitude,
      this.map
    );
  };

  updatePudoPointAccordingToCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        this.sortPudoPoints(
          position.coords.latitude,
          position.coords.longitude
        );
        this.setState({
          showLocateAddress: false,
          sortedOnJob: false,
          addressValue: "Your Address",
        });
      });
    }
  };

  mapFitBound(bounds, lat, lng, map) {
    this.map = map;
    this.bounds = bounds;
    this.jobLatitude = lat;
    this.jobLongitude = lng;
    map.fitBounds([bounds, this.jobMarker], {
      paddingBottomRight: [20, 250],
      paddingTopLeft: [20, 50],
    });
  }

  backToPreviousPage = () => {
    this.backToScreen();
  };

  updatePudo = async (pudo) => {
    let form = {
      processInteractionKeysDtos: [],
    };
    let updateList = this.props.updateProcessAttributeList;
    for (let i = 0; i < updateList.length; i++) {
      if (
        this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey] !=
          undefined &&
        !isEmpty(
          this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey]
        )
      ) {
        let attributes = {
          key: updateList[i]["processAttributeKey"],
          value: this.props.dsMappedKeyValue[pudo.id][
            updateList[i].dsAttributeKey
          ],
          typeId: updateList[i]["processAttributeTypeId"],
        };
        form["processInteractionKeysDtos"].push(attributes);
      }
    }

    if (!isEmpty(form["processInteractionKeysDtos"])) {
      let res = await this.props.actions.updateFormData(
        form.processInteractionKeysDtos,
        this.props.trackingDetails.pudo.updateProcessMasterCode,
        this.props.pudpReferenceNumber,
        this.props.url,
        "IS_PUDO",
        pudo
      );
      if (res == "200") {
        this.errorMessage = "";
        this.setState({ pudoUpdated: true });
      } else {
        this.errorMessage = containerConstants.formatString(
          containerConstants.OopsSomethingWentWrongPleaseTryAfterSomeTime
        );
        this.setState({ pudoUpdated: false, pudoError: true });
      }
    }
  };

  checkAddressValue = (addressValue) => {
    if (!isEmpty(addressValue)) {
      return addressValue;
    }
  };

  render() {
    if (this.state.pudoUpdated) {
      this.props.updatedShop();
    }
    if (this.state.shopDetail) {
      return (
        <ShopDetail
          detail={this.storeSingleDetail}
          detailIndex={this.storeSingleDetailIndex}
          gobackBtn={this.closeCardDetail}
          primaryBgColor={this.props.primaryBgColor}
          unit={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.distanceUnit &&
            (this.props.trackingDetails.pudo.distanceUnit == "kilometers"
              ? "Km"
              : this.props.trackingDetails.pudo.distanceUnit)
          }
          delayMessage={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.delayMessage
          }
          attemptCount={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.attemptCount
          }
          updatePudoShops={this.updatePudo}
          pudoError={this.state.pudoError}
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
          searchLocationLat={this.searchLocationLat}
          searchLocationLng={this.searchLocationLng}
          pudoConfig={this.props.trackingDetails.pudo}
        />
      );
    }

    if (this.state.showLocateAddress) {
      return (
        <LocateAddress
          back={() => this.setState({ showLocateAddress: false })}
          currentLocation={this.updatePudoPointAccordingToCurrentLocation}
          searchLocation={this.setSearchAddress}
          addressValue={this.checkAddressValue(this.state.addressValue)}
        />
      );
    }

    if (this.state.pudoList) {
      return (
        <React.Fragment>
          <div className=" pt10 pr15 pl0 flex align-center bg-white">
            <div className="p10">
              <ArrowBack className="fs24" onClick={this.backToPreviousPage} />
            </div>
            <TextField
              id="standard-name"
              margin="normal"
              className="width-100 input-p-15"
              style={{ marginTop: 0, marginLeft: 5 }}
              placeholder={containerConstants.formatString(
                containerConstants.SearchForPickupPointNearYou
              )}
              autoComplete="off"
              value={this.state.addressValue}
              onFocus={() => this.setState({ showLocateAddress: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.addressValue ? (
                      !isEmpty(this.state.addressValue) && (
                        <IconButton
                          aria-label="clear input"
                          onClick={this.onInputClear}
                        >
                          <ClearIcon />
                        </IconButton>
                      )
                    ) : (
                      <div className="p10">
                        <SearchIcon className="fs24" />
                      </div>
                    )}
                  </InputAdornment>
                ),
                style:{fontFamily:`${this.fontType||"Roboto"},sans-serif`}}
              }
            />
          </div>
          <PudoScreenList
            availableList={this.props.pudoPoints}
            backAvailableLocation={this.clickBackAvailableLocationScreen}
            onForwardArrowClick={this.openCardDetail}
            primaryBgColor={this.props.primaryBgColor}
            unit={this.props.pudoConfig.distanceUnit}
            primaryTextColor={this.props.primaryTextColor}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div className="pudo-shop-map">
          <div className=" pt10 pr15 pl0 flex align-center bg-white">
            <div className="p10">
              <ArrowBack className="fs24" onClick={this.backToPreviousPage} />
            </div>
            <TextField
              id="standard-name"
              margin="normal"
              className="width-100 input-p-15"
              style={{ marginTop: 0, marginLeft: 5 }}
              placeholder={containerConstants.formatString(
                containerConstants.SearchForPickupPointNearYou
              )}
              autoComplete="off"
              value={this.state.addressValue}
              onFocus={() => this.setState({ showLocateAddress: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.addressValue ? (
                      !isEmpty(this.state.addressValue) && (
                        <IconButton
                          aria-label="clear input"
                          onClick={this.onInputClear}
                        >
                          <ClearIcon />
                        </IconButton>
                      )
                    ) : (
                      <div className="p10">
                        <SearchIcon className="fs24" />
                      </div>
                    )}
                  </InputAdornment>
                ),
                style:{fontFamily:`${this.fontType||"Roboto"},sans-serif`},
              }}
            />
          </div> 
          <MapView
            availableLocationList={this.props.pudoPoints}
            points={this.points}
            mapFitBound={this.mapFitBound}
          />
          <div className="mylocationDiv" onClick={this.setCurrentLocation}>
            <MyLocation />
          </div>
          <div className="viewListDiv" onClick={this.openPudoScreenList}>
            <ViewListIcon color={this.props.secondaryBgColor} />
          </div>
          <div className="card-view">{this.renderCardLayout()}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  trackingDetails: state.customerInteractionReducer.trackingDetails,
  url: state.customerInteractionReducer.url,
  updateProcessAttributeList: state.pudoReducer.updateProcessAttributeList,
  dsMappedKeyValue: state.pudoReducer.dsMappedKeyValue,
  pudpReferenceNumber: state.pudoReducer.referenceNumber,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setPudoPoints, ...CustomerInteractionAction },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableLocation);
