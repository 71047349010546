import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LocateAddress from '../LocateAddress/index'
import { isEmpty, cloneDeep } from 'lodash'
import Map from '../../components/Map';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {formServices} from '../ProcessUpdateForm/FormServices';
import FormAttributes from '../ProcessUpdateForm/FormAttributes';



const ColorButton = withStyles(theme => ({
    root: {
      width: "100%",
      height: 50
    },
  }))(Button);




class UpdateAddress extends React.Component {

    addressComponent;
    isUpdated = false;
    isDisabled=false;
    constructor(props){
         super(props);
         this.state ={
             height:0,
             form:{},
             lat:0,
             lng:0,
             reverseAddress:'',
             showLocateAddress:false,
             showAlert:false
         }
         this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
    }

    componentDidMount() {
         if(this.props.form){
             const form = this.props.form;
              this.setState({'form':form})
         }
         if(isEmpty(this.props.location)){
            this.getCurrentLocation();
         } else {
            let latlng = this.props.location.split(',');
            this.setReverseAddress(latlng[0],latlng[1]);
         }
         this.updateWindowDimensions();
         window.addEventListener('resize', this.updateWindowDimensions);
      
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

    updateWindowDimensions = () =>{
        this.setState({ height: window.innerHeight - document.getElementById('top-area').offsetHeight - document.getElementById('top-area').offsetHeight });
    }

    updateAttributeValues =(index,key,value)=>{
        if(key){
          const form = this.state.form;
          form.processInteractionKeysDtos[index]["value"] = value;
          this.setState({form});
        }  
    }


   handleClick = (e) =>{
       if(e.latlng){
         this.setReverseAddress(e.latlng.lat,e.latlng.lng);
       }
       if(e.lat || e.lng){
        this.setReverseAddress(e.lat,e.lng);
       }
   }

   backToUpdateAddress =(event)=>{
       this.setState({'showLocateAddress':false})
   }

   checkIfLatLng =(attribute,index)=>event=>{}

  getDistanceFromLatLonInKm = async(lat2,lon2)=> {
    if(isEmpty(this.props.radius) || isEmpty(this.props.circleCenter)){
      return true
    }
    let lat1 = parseFloat(this.props.circleCenter[0] || "0");
    let lon1 = parseFloat(this.props.circleCenter[1] || "0");
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = (0.5 - c((lat2 - lat1) * p)/2) +(c(lat1 * p) * c(lat2 * p))*((1 - c((lon2 - lon1) * p))/2);
    var d= 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    if(d*1000<this.props.radius){
         return true;
    } else {
      return false;
    }
  }

  updateAddress = () => {
    const form = cloneDeep(this.state.form);
    let lat = this.state.lat;
    let lng = this.state.lng;
    form.addressKeys = '';
    if (!isEmpty(this.addressComponent) && !isEmpty(this.state.form.processInteractionKeysDtos)) {
      (this.state.form.processInteractionKeysDtos).map((attribute, index) => {
        if (!isEmpty(attribute.mappingWith) && attribute.mappingWith != "[]") {
          const mapping = JSON.parse(attribute.mappingWith);
          for (let k in mapping) {
            this.addressComponent.map(address => {
              if (address.types[0] == mapping[k]) {
                  form.processInteractionKeysDtos[index].value = isEmpty(form.processInteractionKeysDtos[index].value) ?
                                                                      address.long_name : form.processInteractionKeysDtos[index].value + ', ' + address.long_name;
              }
            })
          }
        } else if (attribute.typeId == 52 && lat != 0) {
          form.locationValue = lat + "," + lng;
        } else if (attribute.addressPart) {
          
          var updatedValue ;
          if(this.state.formDetailsMap[attribute.key] && !this.state.formDetailsMap[attribute.key].hide){
            updatedValue = this.state.formDetailsMap[attribute.key].value ;
            form.processInteractionKeysDtos[index].value = updatedValue ;
          }else{
            updatedValue = attribute.value;
          }
          
          if(!isEmpty(updatedValue)){
            form.addressKeys = isEmpty(form.addressKeys) ? updatedValue : form.addressKeys.concat(", ").concat(updatedValue);
          }
          
        }
      })
    }
    if (!isEmpty(this.state.reverseAddress)) {
      form.addressKeys = isEmpty(form.addressKeys) ? this.state.reverseAddress : form.addressKeys.concat(", ").concat(this.state.reverseAddress);
    }

    this.props.saveAddress(form, this.state.formDetailsMap);
  }

  checkForInsideCircle = async (location) => {
    if(isEmpty(location)) {
      return true;
    }

    let isInsideCircle = true;
    if(location.latitude && location.longitude) {
      isInsideCircle = await this.getDistanceFromLatLonInKm(location.latitude,location.longitude);
    } else if(location.geometry && location.geometry.location && location.geometry.location.lat) {
      isInsideCircle = await this.getDistanceFromLatLonInKm(location.geometry.location.lat,location.geometry.location.lng);
    } else {
      isInsideCircle = await this.getDistanceFromLatLonInKm(location.lat,location.lng);
    }

    return isInsideCircle;
  }


setSearchAddress =async(location)=>{
  if(!isEmpty(location)){
   const isInsideCircle = await this.checkForInsideCircle(location);
   if(isInsideCircle){
     if(this.herePoweredAccount){
      this.addressComponent = location.address.label;
      this.setState({
        lat: location.latitude,
        lng: location.longitude,
        reverseAddress:location.address.label,
        showLocateAddress:false
      })
    }
    else{
    this.addressComponent = location.address_components;
      this.setState({
        lat: location.geometry.location.lat,
        lng: location.geometry.location.lng,
        reverseAddress:location.formatted_address,
        showLocateAddress:false
      })
    }
   } else {
     this.setState({showLocateAddress:false,'showAlert':true})
   }
  }
}

  setReverseAddress = async(lat,lng)=>{
    console.log("latlng value address",lat,lng);

    let isInsideCircle = await this.getDistanceFromLatLonInKm(lat,lng);
     if(isInsideCircle){
      if(this.herePoweredAccount){
        let address = lat+","+lng;
        let results = "";
        if(results.items[0]){
          console.log(results.items[0].address.label)
          this.addressComponent = results.items[0];
          this.setState({
            lat: lat,
            lng: lng,
            reverseAddress:results.items[0].address.label,
            showLocateAddress:false
          })
        }
      }else{
        var geocoder = new google.maps.Geocoder;
        var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
        geocoder.geocode({'location': latlng}, function(results, status) {
          console.log("Resp",status,results)
          if (status === 'OK' && results[0]) {
              this.addressComponent = results[0].address_components;
              this.setState({
                lat: lat,
                lng: lng,
                reverseAddress:results[0].formatted_address,
                showLocateAddress:false
              })
            } else {
              return '';
           }
        }.bind(this));
      }
     } else {
        this.setState({'showAlert':true,showLocateAddress:false});
     }
  }


  renderAddressPartAttributes = () => {
    let attributeList = [];

    if (!isEmpty(this.state.form)) {

      var formDetailsMap = formServices.getFormDetailsMap(this.state.form.processInteractionKeysDtos, true);
      if (!this.state.formDetailsMap) {
        this.runBeforeValidation(formDetailsMap)
      }
      if (!isEmpty(this.state.form.processInteractionKeysDtos) && !isEmpty(this.state.formDetailsMap)) {
        (this.state.form.processInteractionKeysDtos).map((attribute, index) => {

          if (attribute.addressPart  && (isEmpty(attribute.mappingWith) || attribute.mappingWith == "[]")) {
            attributeList.push(
              <FormAttributes
                key={attribute.key}
                updateValue={this.updateValue}
                runValidation={this.runValidation}
                formDetailsMap={this.state.formDetailsMap}
                attribute={attribute}
                index={index}
                updateAttributeValue={this.updateAttributeValues}
                checkLatLng={this.checkIfLatLng}
                fontColor={this.state.form.fontColor}
                fontType={this.state.form.fontType}
                fontSize={this.state.form.fontSize}
                calendarType={this.state.form.calendarType}
                language={this.props.language}
                fontSize={this.props.fontSize}
                fontFamily={this.props.fontFamily}
                isAddressSubmitEnabled={true}
                holidayMaster={(this.state.form && this.state.form.holidayMaster) ? this.state.form.holidayMaster : {}}
                trackingDetails={this.props.trackingDetails}
              />);
          }

        });
      }
    }
    return attributeList;

  }
  runBeforeValidation = (formDetailsMap) => {
      formServices.runAllValidation(formDetailsMap,"before");
      this.setState({formDetailsMap})
  }

  updateValue = (key, value) => {
      var formDetailsMap=cloneDeep(this.state.formDetailsMap);
      formDetailsMap[key].value = value;
      this.setState({formDetailsMap})
  } 
  
  runValidation = (key, executionTime) => {
      var formDetailsMap=cloneDeep(this.state.formDetailsMap);
      formServices.runValidation(formDetailsMap,key,executionTime);
      this.setState({formDetailsMap})
  }

  isSumbitEnabled= () => {
      if(!this.state.formDetailsMap) return false;
  
      for (let key in this.state.formDetailsMap) {
        var detailObj = this.state.formDetailsMap[key];
        if (detailObj.required && !detailObj.hide) {
              if (isEmpty(detailObj.value)){
                return false;
  
              }
        }
      }
  
      return true;
  }

     getCurrentLocation = ()=>{
      const location = window.navigator && window.navigator.geolocation
      if (location) {
          location.getCurrentPosition(async(position) => { 
                this.setReverseAddress(position.coords.latitude, position.coords.longitude)
            }) 
          } 
     }
    setCurrentLocation =()=>event=>{
        this.getCurrentLocation();   
    }

    scrollBodyOnKeyboard =()=>{
        var scrollingElement = (document.querySelector(".scrollingContainer")); 
        setTimeout(function(){
          scrollingElement.scrollTop = scrollingElement.scrollHeight;
        }, 300)
    }

    getAlertDialog =() =>{
        return(
         <Dialog 
            open={this.state.showAlert}
            onClose={event=>{this.setState({'showAlert':false})}} 
            aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText>
              {containerConstants.formatString(containerConstants.PleaseUpdateLocationInsideCircle)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={event=>{this.setState({'showAlert':false})}} color="primary" autoFocus>
            {containerConstants.formatString(containerConstants.Ok)}

            </Button>
          </DialogActions>
     </Dialog>
      )
    }

  
      render(){
        const {primaryBgColor, primaryTextColor, fontSize, fontFamily, secondaryBgColor, secondaryTextColor}=this.props;
        const fontType = this.props.trackingDetails?.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO.newCiData.defaultSettings.typeface.fontType:"Roboto";        
        if(!this.isUpdated && this.state.form.locationValue && !isEmpty(this.state.form.locationValue) && this.state.lat==0){
            let latlng = (this.state.form.locationValue).split(',');
            this.isUpdated = true;
            this.setReverseAddress(latlng[0],latlng[1]);
        } 
       
        if(this.state.showLocateAddress){
          return <LocateAddress 
                    back={this.backToUpdateAddress} 
                    currentLocation={this.setCurrentLocation()} 
                    searchLocation={this.setSearchAddress}
                    fontColor ={this.state.form.fontColor}
                    fontType = {this.state.form.fontType}
                    fontSize = {this.state.form.fontSize}                    
                  />
        }
         
        return (
              <div>
                  <div id="top-area">
                    <Header 
                      icon={true} 
                      heading ={ "Add Delivery Address"}
                      back ={true} 
                      backScreen = {this.props.handleClick()}
                      primaryBgColor={primaryBgColor? primaryBgColor: this.state.form.bgColor}
                      primaryTextColor={primaryTextColor? primaryTextColor:this.state.form.fontColor}
                      fontSize={fontSize? fontSize:this.state.form.fontSize }
                      fontFamily={fontFamily?fontFamily: this.state.form.fontType}

                      ></Header>
                  </div>

                  <div className="bg-white content overflow-y scrollingContainer map-address" style={{ height: this.state.height - 16}}>
                  <Map lat={this.state.lat} lng={this.state.lng} handleClick ={this.handleClick} currentLocation={this.setCurrentLocation()} radius={this.props.radius} circleCenter={this.props.circleCenter}/>                
                    <div className="mb5 p15">
                      <TextField
                        id="standard-name"
                        label={containerConstants.formatString(containerConstants.LOCATION)}                            
                        margin="normal"
                        className="width-100"
                        value={this.state.reverseAddress}
                        autoComplete='off'
                        onClick ={event=>{this.setState({'showLocateAddress':true})}}
                        InputLabelProps={{
                            style: { color: this.state.form.fontColor,fontFamily:`${fontType||"Roboto"},sans-serif`,fontSize:this.state.form.fontSize },
                          }}
                          InputProps={{
                            style: { color: this.state.form.fontColor,fontFamily:`${fontType||"Roboto"},sans-serif`,fontSize:this.state.form.fontSize },
                          }}
                      />
                      {this.renderAddressPartAttributes()}
                      {this.getAlertDialog()}
                    </div>
                    
                  </div>
            <Footer id="bottom-area">
              <span style={{ cursor: 'not-allowed' }}>
                <ColorButton variant="contained" color="primary" onClick={this.updateAddress} disabled={!this.isSumbitEnabled()}
                  style={{ background: this.isDisabled ? primaryBgColor : primaryBgColor ? primaryBgColor : this.state.form.saveBgColor }} >
                  <span style={{
                    color: this.isDisabled ? '' : primaryTextColor ? primaryTextColor : this.state.form.saveFontColor,
                    fontFamily:`${fontType||"Roboto"},sans-serif`,
                    fontSize: fontSize ? fontSize : this.state.form.fontSize
                  }}>
                    {(this.props.customScreen)
                      ? containerConstants.formatString(containerConstants.SAVE_LOCATION)
                      : !isEmpty(this.state.form.secondarySaveButtonTitle)
                        ? this.state.form.secondarySaveButtonTitle
                        : (!isEmpty(this.state.form.saveButtonTitle)
                          ? this.state.form.saveButtonTitle
                          : containerConstants.formatString(containerConstants.Update))}
                  </span>
                </ColorButton>
              </span>
            </Footer>

            </div>
          )
      }
}


export default UpdateAddress;

